import React from 'react';
import { oneOfType, string } from 'prop-types';
import classNames from 'classnames';

import { propTypes } from '../../util/types';

import css from './ResponsiveBackgroundWatermark.module.css';
import watermark from '../../assets/rentimo_watermark.svg';

const ResponsiveBackgroundWatermark = props => {
  const {
    className,
    rootClassName,
    image,
    as,
    alt,
    ...otherProps
  } = props;

  const Tag = as || 'div';

  const classes = classNames(rootClassName || css.root, className);

  return (
    <Tag className={classes} {...otherProps}>
      <img className={css.backgroundImage} src={watermark}/>
    </Tag>
  );
};

ResponsiveBackgroundWatermark.defaultProps = {
  className: null,
  rootClassName: null,
  as: 'div',
  image: null,
  alt: null,
};

ResponsiveBackgroundWatermark.propTypes = {
  className: string,
  rootClassName: string,
  as: string,
  image: oneOfType([string, propTypes.imageAsset]),
  alt: string,
};

export default ResponsiveBackgroundWatermark;
